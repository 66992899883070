const controls = {
  children: [
    {
      name: 'Learning Programming',
      btn: 'Start Learning',
      title: 'I need a Learning Sessions',
      sub_title: 'Start your Learning Path from Here ..',
      key: 'find_program',
      children: [
        {
          name: 'Programming',
          key: '_programming',
          img: 'https://hackr.io/assets/images/nav-icons/programming.svg',
          children: [
            {
              name: 'Python',
              img:
                'https://hackr.io/tutorials/python/logo-python.svg?ver=1562823957',
              children: [],
            },
            {
              name: 'JavaScript',
              children: [],
              img:
                'https://hackr.io/tutorials/javascript/logo-javascript.svg?ver=1553668920',
            },
            {
              name: 'Android Development',
              children: [],
              img:
                'https://hackr.io/tutorials/android-development/logo-android-development.svg?ver=1550798222',
            },
            {
              name: 'Java',
              children: [],
              img:
                'https://hackr.io/tutorials/java/logo-java.svg?ver=1550798239',
            },
            {
              name: 'React',
              children: [],
              img:
                'https://hackr.io/tutorials/react/logo-react.svg?ver=1555134603',
            },
            {
              name: 'C++',
              children: [],
              img:
                'https://hackr.io/tutorials/c-plus-plus/logo-c-plus-plus.svg?ver=1550843650',
            },
            {
              name: 'Data Structures and Algorithms',
              children: [],
              img:
                'https://hackr.io/tutorials/data-structures-algorithms/logo-data-structures-algorithms.svg?ver=1550834269',
            },
            {
              name: 'Angular',
              children: [],
              img:
                'https://hackr.io/tutorials/angular/logo-angular.svg?ver=1550774724',
            },
            {
              name: 'HTML 5',
              children: [],
              img:
                'https://hackr.io/tutorials/html-5/logo-html-5.svg?ver=1555389548',
            },
            {
              name: 'CSS',
              children: [],
              img: 'https://hackr.io/tutorials/css/logo-css.svg?ver=1550727061',
            },
            {
              name: 'Node.js',
              children: [],
              img:
                'https://hackr.io/tutorials/node-js/logo-node-js.svg?ver=1555389930',
            },
            {
              name: 'Git',
              children: [],
              img: 'https://hackr.io/tutorials/git/logo-git.svg?ver=1550796487',
            },
            {
              name: 'Bootstrap',
              children: [],
              img:
                'https://hackr.io/tutorials/bootstrap/logo-bootstrap.svg?ver=1555328097',
            },
            {
              name: 'MySQL',
              children: [],
              img:
                'https://hackr.io/tutorials/mysql/logo-mysql.svg?ver=1550613740',
            },
            {
              name: 'SQL',
              children: [],
              img: 'https://hackr.io/tutorials/sql/logo-sql.svg?ver=1555309685',
            },
            {
              name: 'React Native',
              children: [],
              img:
                'https://hackr.io/tutorials/react-native/logo-react-native.svg?ver=1555132575',
            },
            {
              name: 'Vue.js',
              children: [],
              img:
                'https://hackr.io/tutorials/vue-js/logo-vue-js.svg?ver=1555390471',
            },
            {
              name: 'TypeScript',
              children: [],
              img:
                'https://hackr.io/tutorials/typescript/logo-typescript.svg?ver=1550646462',
            },
            {
              name: 'Java Spring Framework',
              children: [],
              img:
                'https://hackr.io/tutorials/java-spring-framework/logo-java-spring-framework.svg?ver=1555390385',
            },
            {
              name: 'Ruby',
              children: [],
              img:
                'https://hackr.io/tutorials/ruby/logo-ruby.svg?ver=1550555255',
            },
            {
              name: 'Redux',
              children: [],
              img:
                'https://hackr.io/tutorials/redux/logo-redux.svg?ver=1551354757',
            },
            {
              name: 'PostgreSQL',
              children: [],
              img:
                'https://hackr.io/tutorials/postgresql/logo-postgresql.svg?ver=1555390079',
            },
            {
              name: 'Electron',
              children: [],
              img:
                'https://hackr.io/tutorials/electron/logo-electron.svg?ver=1551354757',
            },
            {
              name: 'Ruby on Rails',
              children: [],
              img:
                'https://hackr.io/tutorials/ruby-on-rails/logo-ruby-on-rails.svg?ver=1551354757',
            },
            {
              name: 'Elastic Search',
              children: [],
              img:
                'https://hackr.io/tutorials/elastic-search/logo-elastic-search.svg?ver=1558117410',
            },
          ],
        },
        {
          name: 'DevOps',
          key: '_devops',
          img: 'https://hackr.io/sites/devops/icon?ver=1563170458',
          children: [
            {
              name: 'Linux System Administration',
              children: [],
              img:
                'https://hackr.io/tutorials/linux-system-administration/logo-linux-system-administration.svg?ver=1555389741',
            },
            {
              name: 'AWS',
              children: [],
              img:
                'https://hackr.io/tutorials/amazon-web-services-aws/logo-amazon-web-services-aws.svg?ver=1555328044',
            },
            {
              name: 'Docker',
              children: [],
              img:
                'https://hackr.io/tutorials/docker/logo-docker.svg?ver=1555389348',
            },
            {
              name: 'Google Cloud Platform',
              children: [],
              img:
                'https://hackr.io/tutorials/google-cloud-platform/logo-google-cloud-platform.svg?ver=1551354757',
            },
            {
              name: 'Kubernetes',
              children: [],
              img:
                'https://hackr.io/tutorials/kubernetes/logo-kubernetes.svg?ver=1555389652',
            },
            {
              name: 'Jenkins',
              children: [],
              img:
                'https://hackr.io/tutorials/jenkins/logo-jenkins.svg?ver=1560403209',
            },
            {
              name: 'Ansible',
              children: [],
              img:
                'https://hackr.io/tutorials/ansible/logo-ansible.svg?ver=1550847568',
            },
            {
              name: 'System Architecture',
              children: [],
              img:
                'https://hackr.io/tutorials/system-architecture/logo-system-architecture.svg?ver=1551354757',
            },
            {
              name: 'Microsoft Azure',
              children: [],
              img:
                'https://hackr.io/tutorials/microsoft-azure/logo-microsoft-azure.svg?ver=1551354757',
            },
            {
              name: 'Windows Server Administration',
              children: [],
              img:
                'https://hackr.io/tutorials/windows-server-administration/logo-windows-server-administration.svg?ver=1551354757',
            },
            {
              name: 'Apache Kafka',
              children: [],
              img:
                'https://hackr.io/tutorials/apache-kafka/logo-apache-kafka.svg?ver=1551354757',
            },
            {
              name: 'Serverless Computing',
              children: [],
              img:
                'https://hackr.io/tutorials/serverless-computing/logo-serverless-computing.svg?ver=1551354757',
            },
            {
              name: 'Vagrant',
              children: [],
              img:
                'https://hackr.io/tutorials/vagrant/logo-vagrant.svg?ver=1557980642',
            },
            {
              name: 'Puppet',
              children: [],
              img:
                'https://hackr.io/tutorials/puppet/logo-puppet.svg?ver=1558177620',
            },
          ],
        },
        {
          name: 'Design',
          key: '_design',
          img: 'https://hackr.io/sites/design/icon?ver=1571096431',
          children: [
            {
              name: 'Python',
              img:
                'https://hackr.io/tutorials/python/logo-python.svg?ver=1562823957',
              children: [],
            },
            {
              name: 'JavaScript',
              children: [],
              img:
                'https://hackr.io/tutorials/javascript/logo-javascript.svg?ver=1553668920',
            },
            {
              name: 'Android Development',
              children: [],
              img:
                'https://hackr.io/tutorials/android-development/logo-android-development.svg?ver=1550798222',
            },
            {
              name: 'Java',
              children: [],
              img:
                'https://hackr.io/tutorials/java/logo-java.svg?ver=1550798239',
            },
            {
              name: 'React',
              children: [],
              img:
                'https://hackr.io/tutorials/react/logo-react.svg?ver=1555134603',
            },
            {
              name: 'C++',
              children: [],
              img:
                'https://hackr.io/tutorials/c-plus-plus/logo-c-plus-plus.svg?ver=1550843650',
            },
            {
              name: 'Data Structures and Algorithms',
              children: [],
              img:
                'https://hackr.io/tutorials/data-structures-algorithms/logo-data-structures-algorithms.svg?ver=1550834269',
            },
            {
              name: 'Angular',
              children: [],
              img:
                'https://hackr.io/tutorials/angular/logo-angular.svg?ver=1550774724',
            },
            {
              name: 'HTML 5',
              children: [],
              img:
                'https://hackr.io/tutorials/html-5/logo-html-5.svg?ver=1555389548',
            },
            {
              name: 'CSS',
              children: [],
              img: 'https://hackr.io/tutorials/css/logo-css.svg?ver=1550727061',
            },
            {
              name: 'Node.js',
              children: [],
              img:
                'https://hackr.io/tutorials/node-js/logo-node-js.svg?ver=1555389930',
            },
            {
              name: 'Git',
              children: [],
              img: 'https://hackr.io/tutorials/git/logo-git.svg?ver=1550796487',
            },
            {
              name: 'Bootstrap',
              children: [],
              img:
                'https://hackr.io/tutorials/bootstrap/logo-bootstrap.svg?ver=1555328097',
            },
            {
              name: 'MySQL',
              children: [],
              img:
                'https://hackr.io/tutorials/mysql/logo-mysql.svg?ver=1550613740',
            },
            {
              name: 'SQL',
              children: [],
              img: 'https://hackr.io/tutorials/sql/logo-sql.svg?ver=1555309685',
            },
            {
              name: 'React Native',
              children: [],
              img:
                'https://hackr.io/tutorials/react-native/logo-react-native.svg?ver=1555132575',
            },
            {
              name: 'Vue.js',
              children: [],
              img:
                'https://hackr.io/tutorials/vue-js/logo-vue-js.svg?ver=1555390471',
            },
            {
              name: 'TypeScript',
              children: [],
              img:
                'https://hackr.io/tutorials/typescript/logo-typescript.svg?ver=1550646462',
            },
            {
              name: 'Java Spring Framework',
              children: [],
              img:
                'https://hackr.io/tutorials/java-spring-framework/logo-java-spring-framework.svg?ver=1555390385',
            },
            {
              name: 'Ruby',
              children: [],
              img:
                'https://hackr.io/tutorials/ruby/logo-ruby.svg?ver=1550555255',
            },
            {
              name: 'Redux',
              children: [],
              img:
                'https://hackr.io/tutorials/redux/logo-redux.svg?ver=1551354757',
            },
            {
              name: 'PostgreSQL',
              children: [],
              img:
                'https://hackr.io/tutorials/postgresql/logo-postgresql.svg?ver=1555390079',
            },
            {
              name: 'Electron',
              children: [],
              img:
                'https://hackr.io/tutorials/electron/logo-electron.svg?ver=1551354757',
            },
            {
              name: 'Ruby on Rails',
              children: [],
              img:
                'https://hackr.io/tutorials/ruby-on-rails/logo-ruby-on-rails.svg?ver=1551354757',
            },
            {
              name: 'Elastic Search',
              children: [],
              img:
                'https://hackr.io/tutorials/elastic-search/logo-elastic-search.svg?ver=1558117410',
            },
          ],
        },
      ],
    },
    {
      name: 'Test your Learning Knowledge of any Tehcnology',
      title: 'Test knowledge using Tech Quiz',
      btn: 'Take Quiz',
      sub_title: 'Test Your Knowledge',
      key: 'test_job',
      children: [
        {
          name: 'Programming',
          key: '_programming',
          img: 'https://hackr.io/assets/images/nav-icons/programming.svg',
          children: [
            {
              name: 'Python',
              img:
                'https://hackr.io/tutorials/python/logo-python.svg?ver=1562823957',
              children: [],
            },
            {
              name: 'JavaScript',
              children: [],
              img:
                'https://hackr.io/tutorials/javascript/logo-javascript.svg?ver=1553668920',
            },
            {
              name: 'Android Development',
              children: [],
              img:
                'https://hackr.io/tutorials/android-development/logo-android-development.svg?ver=1550798222',
            },
            {
              name: 'Java',
              children: [],
              img:
                'https://hackr.io/tutorials/java/logo-java.svg?ver=1550798239',
            },
            {
              name: 'React',
              children: [],
              img:
                'https://hackr.io/tutorials/react/logo-react.svg?ver=1555134603',
            },
            {
              name: 'C++',
              children: [],
              img:
                'https://hackr.io/tutorials/c-plus-plus/logo-c-plus-plus.svg?ver=1550843650',
            },
            {
              name: 'Data Structures and Algorithms',
              children: [],
              img:
                'https://hackr.io/tutorials/data-structures-algorithms/logo-data-structures-algorithms.svg?ver=1550834269',
            },
            {
              name: 'Angular',
              children: [],
              img:
                'https://hackr.io/tutorials/angular/logo-angular.svg?ver=1550774724',
            },
            {
              name: 'HTML 5',
              children: [],
              img:
                'https://hackr.io/tutorials/html-5/logo-html-5.svg?ver=1555389548',
            },
            {
              name: 'CSS',
              children: [],
              img: 'https://hackr.io/tutorials/css/logo-css.svg?ver=1550727061',
            },
            {
              name: 'Node.js',
              children: [],
              img:
                'https://hackr.io/tutorials/node-js/logo-node-js.svg?ver=1555389930',
            },
            {
              name: 'Git',
              children: [],
              img: 'https://hackr.io/tutorials/git/logo-git.svg?ver=1550796487',
            },
            {
              name: 'Bootstrap',
              children: [],
              img:
                'https://hackr.io/tutorials/bootstrap/logo-bootstrap.svg?ver=1555328097',
            },
            {
              name: 'MySQL',
              children: [],
              img:
                'https://hackr.io/tutorials/mysql/logo-mysql.svg?ver=1550613740',
            },
            {
              name: 'SQL',
              children: [],
              img: 'https://hackr.io/tutorials/sql/logo-sql.svg?ver=1555309685',
            },
            {
              name: 'React Native',
              children: [],
              img:
                'https://hackr.io/tutorials/react-native/logo-react-native.svg?ver=1555132575',
            },
            {
              name: 'Vue.js',
              children: [],
              img:
                'https://hackr.io/tutorials/vue-js/logo-vue-js.svg?ver=1555390471',
            },
            {
              name: 'TypeScript',
              children: [],
              img:
                'https://hackr.io/tutorials/typescript/logo-typescript.svg?ver=1550646462',
            },
            {
              name: 'Java Spring Framework',
              children: [],
              img:
                'https://hackr.io/tutorials/java-spring-framework/logo-java-spring-framework.svg?ver=1555390385',
            },
            {
              name: 'Ruby',
              children: [],
              img:
                'https://hackr.io/tutorials/ruby/logo-ruby.svg?ver=1550555255',
            },
            {
              name: 'Redux',
              children: [],
              img:
                'https://hackr.io/tutorials/redux/logo-redux.svg?ver=1551354757',
            },
            {
              name: 'PostgreSQL',
              children: [],
              img:
                'https://hackr.io/tutorials/postgresql/logo-postgresql.svg?ver=1555390079',
            },
            {
              name: 'Electron',
              children: [],
              img:
                'https://hackr.io/tutorials/electron/logo-electron.svg?ver=1551354757',
            },
            {
              name: 'Ruby on Rails',
              children: [],
              img:
                'https://hackr.io/tutorials/ruby-on-rails/logo-ruby-on-rails.svg?ver=1551354757',
            },
            {
              name: 'Elastic Search',
              children: [],
              img:
                'https://hackr.io/tutorials/elastic-search/logo-elastic-search.svg?ver=1558117410',
            },
          ],
        },
        {
          name: 'DevOps',
          key: '_devops',
          img: 'https://hackr.io/sites/devops/icon?ver=1563170458',
          children: [
            {
              name: 'Linux System Administration',
              children: [],
              img:
                'https://hackr.io/tutorials/linux-system-administration/logo-linux-system-administration.svg?ver=1555389741',
            },
            {
              name: 'AWS',
              children: [],
              img:
                'https://hackr.io/tutorials/amazon-web-services-aws/logo-amazon-web-services-aws.svg?ver=1555328044',
            },
            {
              name: 'Docker',
              children: [],
              img:
                'https://hackr.io/tutorials/docker/logo-docker.svg?ver=1555389348',
            },
            {
              name: 'Google Cloud Platform',
              children: [],
              img:
                'https://hackr.io/tutorials/google-cloud-platform/logo-google-cloud-platform.svg?ver=1551354757',
            },
            {
              name: 'Kubernetes',
              children: [],
              img:
                'https://hackr.io/tutorials/kubernetes/logo-kubernetes.svg?ver=1555389652',
            },
            {
              name: 'Jenkins',
              children: [],
              img:
                'https://hackr.io/tutorials/jenkins/logo-jenkins.svg?ver=1560403209',
            },
            {
              name: 'Ansible',
              children: [],
              img:
                'https://hackr.io/tutorials/ansible/logo-ansible.svg?ver=1550847568',
            },
            {
              name: 'System Architecture',
              children: [],
              img:
                'https://hackr.io/tutorials/system-architecture/logo-system-architecture.svg?ver=1551354757',
            },
            {
              name: 'Microsoft Azure',
              children: [],
              img:
                'https://hackr.io/tutorials/microsoft-azure/logo-microsoft-azure.svg?ver=1551354757',
            },
            {
              name: 'Windows Server Administration',
              children: [],
              img:
                'https://hackr.io/tutorials/windows-server-administration/logo-windows-server-administration.svg?ver=1551354757',
            },
            {
              name: 'Apache Kafka',
              children: [],
              img:
                'https://hackr.io/tutorials/apache-kafka/logo-apache-kafka.svg?ver=1551354757',
            },
            {
              name: 'Serverless Computing',
              children: [],
              img:
                'https://hackr.io/tutorials/serverless-computing/logo-serverless-computing.svg?ver=1551354757',
            },
            {
              name: 'Vagrant',
              children: [],
              img:
                'https://hackr.io/tutorials/vagrant/logo-vagrant.svg?ver=1557980642',
            },
            {
              name: 'Puppet',
              children: [],
              img:
                'https://hackr.io/tutorials/puppet/logo-puppet.svg?ver=1558177620',
            },
          ],
        },
        {
          name: 'Design',
          key: '_design',
          img: 'https://hackr.io/sites/design/icon?ver=1571096431',
          children: [
            {
              name: 'Python',
              img:
                'https://hackr.io/tutorials/python/logo-python.svg?ver=1562823957',
              children: [],
            },
            {
              name: 'JavaScript',
              children: [],
              img:
                'https://hackr.io/tutorials/javascript/logo-javascript.svg?ver=1553668920',
            },
            {
              name: 'Android Development',
              children: [],
              img:
                'https://hackr.io/tutorials/android-development/logo-android-development.svg?ver=1550798222',
            },
            {
              name: 'Java',
              children: [],
              img:
                'https://hackr.io/tutorials/java/logo-java.svg?ver=1550798239',
            },
            {
              name: 'React',
              children: [],
              img:
                'https://hackr.io/tutorials/react/logo-react.svg?ver=1555134603',
            },
            {
              name: 'C++',
              children: [],
              img:
                'https://hackr.io/tutorials/c-plus-plus/logo-c-plus-plus.svg?ver=1550843650',
            },
            {
              name: 'Data Structures and Algorithms',
              children: [],
              img:
                'https://hackr.io/tutorials/data-structures-algorithms/logo-data-structures-algorithms.svg?ver=1550834269',
            },
            {
              name: 'Angular',
              children: [],
              img:
                'https://hackr.io/tutorials/angular/logo-angular.svg?ver=1550774724',
            },
            {
              name: 'HTML 5',
              children: [],
              img:
                'https://hackr.io/tutorials/html-5/logo-html-5.svg?ver=1555389548',
            },
            {
              name: 'CSS',
              children: [],
              img: 'https://hackr.io/tutorials/css/logo-css.svg?ver=1550727061',
            },
            {
              name: 'Node.js',
              children: [],
              img:
                'https://hackr.io/tutorials/node-js/logo-node-js.svg?ver=1555389930',
            },
            {
              name: 'Git',
              children: [],
              img: 'https://hackr.io/tutorials/git/logo-git.svg?ver=1550796487',
            },
            {
              name: 'Bootstrap',
              children: [],
              img:
                'https://hackr.io/tutorials/bootstrap/logo-bootstrap.svg?ver=1555328097',
            },
            {
              name: 'MySQL',
              children: [],
              img:
                'https://hackr.io/tutorials/mysql/logo-mysql.svg?ver=1550613740',
            },
            {
              name: 'SQL',
              children: [],
              img: 'https://hackr.io/tutorials/sql/logo-sql.svg?ver=1555309685',
            },
            {
              name: 'React Native',
              children: [],
              img:
                'https://hackr.io/tutorials/react-native/logo-react-native.svg?ver=1555132575',
            },
            {
              name: 'Vue.js',
              children: [],
              img:
                'https://hackr.io/tutorials/vue-js/logo-vue-js.svg?ver=1555390471',
            },
            {
              name: 'TypeScript',
              children: [],
              img:
                'https://hackr.io/tutorials/typescript/logo-typescript.svg?ver=1550646462',
            },
            {
              name: 'Java Spring Framework',
              children: [],
              img:
                'https://hackr.io/tutorials/java-spring-framework/logo-java-spring-framework.svg?ver=1555390385',
            },
            {
              name: 'Ruby',
              children: [],
              img:
                'https://hackr.io/tutorials/ruby/logo-ruby.svg?ver=1550555255',
            },
            {
              name: 'Redux',
              children: [],
              img:
                'https://hackr.io/tutorials/redux/logo-redux.svg?ver=1551354757',
            },
            {
              name: 'PostgreSQL',
              children: [],
              img:
                'https://hackr.io/tutorials/postgresql/logo-postgresql.svg?ver=1555390079',
            },
            {
              name: 'Electron',
              children: [],
              img:
                'https://hackr.io/tutorials/electron/logo-electron.svg?ver=1551354757',
            },
            {
              name: 'Ruby on Rails',
              children: [],
              img:
                'https://hackr.io/tutorials/ruby-on-rails/logo-ruby-on-rails.svg?ver=1551354757',
            },
            {
              name: 'Elastic Search',
              children: [],
              img:
                'https://hackr.io/tutorials/elastic-search/logo-elastic-search.svg?ver=1558117410',
            },
          ],
        },
      ],
    },
  ],
};
export default controls;
