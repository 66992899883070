/* eslint-disable no-console */
import React from 'react';
import { Row, Container, Col } from 'react-bootstrap';
import HomeHeader from '../common/Header';
import CookieBanner from './CookieBanner';
import Content from './Content';
//import Bg1 from '../../../assets/img/bg1.png';
//import Bg2 from '../../../assets/img/bg2.png';
//import Bg5 from '../../../assets/img/bg5.png';
import './auth.scss';

export const AuthLayout = props => {
  return (
    <React.Fragment>
      <HomeHeader {...props} />
      <div className="body-container page-body">
        <Container>
          <Row>
            <Col
              xs={12}
              md={{ span: 6, offset: 3 }}
              sm={{ span: 8, offset: 2 }}
              lg={{ span: 4, offset: 4 }}
            >
              <Content {...props} />
            </Col>
          </Row>
        </Container>
      </div>
      <CookieBanner />
    </React.Fragment>
  );
};
