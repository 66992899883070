import { gql } from 'apollo-boost';

const forgotPasswordQuery = gql`
  mutation forgotPassword($email: String!) {
    data: forgotPassword(email: $email) {
      success
      description
      message
    }
  }
`;

export default forgotPasswordQuery;
