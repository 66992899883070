import React, { Component } from 'react';
import '../Home/home.scss';
import Banner from '../Home/homeBanner';
import CareControl from './masterControl';

class HomePage extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="page-wrapper">
          <Banner learning={true} />
        </div>
        <CareControl />
      </React.Fragment>
    );
  }
}

export default HomePage;
