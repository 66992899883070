import React from 'react';
import './style.css';
import loader from '../../../assets/img/Ring-Preloader.gif';

const PageLoader = props => (
  <div style={{ textAlign: 'center' }}>
    <img style={{ width: '200px' }} src={loader} alt="loader" {...props} />
  </div>
);

export { PageLoader };
