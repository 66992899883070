/**
 * Summary: Global file
 * Description: list of utility functions used across the application
 * @author Blab Team
 * @date  25.09.2018
 */
import _ from 'lodash';
import { isEmail } from 'validator';
import timezones from '../assets/json/timezones.json';
import countries from '../assets/json/countries.json';

/**
 * Description: isEmpty will check the empty value
 * @param {string} _param
 * @return {boolean}
 */
function compareObject(obj1, obj2) {
  // Loop through properties in object 1
  for (var p in obj1) {
    // Check property exists on both objects
    if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) return false;

    switch (typeof obj1[p]) {
      // Deep compare objects
      case 'object':
        if (!compareObject(obj1[p], obj2[p])) return false;
        break;
      // Compare function code
      case 'function':
        if (
          typeof obj2[p] == 'undefined' ||
          (p != 'compare' && obj1[p].toString() != obj2[p].toString())
        )
          return false;
        break;
      // Compare values
      default:
        if (obj1[p] != obj2[p]) return false;
    }
  }

  // Check object 2 for any extra properties
  for (var p1 in obj2) {
    if (typeof obj2[p1] === 'undefined') return false;
  }
  return true;
}

/**
 * Description: isEmpty will check the empty value
 * @param {string} _param
 * @return {boolean}
 */
function isEmpty(_param) {
  return (
    _param === 'undefined' ||
    _param === undefined ||
    _param === '' ||
    _param === null
  );
}

/**
 * Description: isEmptyObject will check the empty object
 * @param {object} _obj
 * @return {boolean}
 */
function isEmptyObject(_obj) {
  if (_obj == null) return true; // null and undefined are "empty"
  if (_obj.length > 0) return false;
  if (_obj.length === 0) return true;
  for (const key in _obj) {
    if (hasOwnProperty.call(_obj, key)) return false;
  }
  return true;
}

/**
 * Description: get token expiration date
 * @param {string} token
 * @return {date}
 */

function removeData(key) {
  localStorage.removeItem(key);
}

function saveData(key, value) {
  localStorage.setItem(key, value);
}

function getData(key) {
  return localStorage.getItem(key);
}

function setSession(key, value) {
  sessionStorage.setItem(key, value);
}

function getSession(key) {
  return sessionStorage.getItem(key);
}

function clearSession(key) {
  sessionStorage.removeItem(key);
}

/**
 * @param {string} dateTime
 * @returns {string} Date
 */
function getDate(dateTime) {
  let newDateTime;
  if (_.isUndefined(dateTime)) {
    newDateTime = new Date();
  } else {
    newDateTime = new Date(dateTime);
  }
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  let date = newDateTime.getDate();
  if (date < 10) {
    date = `0${date}`;
  }
  return `${date} ${
    monthNames[newDateTime.getMonth()]
  } ${newDateTime.getFullYear()}`;
}

/**
 * @param {string} dateTime
 * @returns {string} Date
 */
function getTime(dateTime) {
  let format = 'am';
  const newDateTime = new Date(dateTime);
  let hours = newDateTime.getHours();
  let mnts = newDateTime.getMinutes();
  if (parseInt(hours) > 12) {
    hours = parseInt(hours) - 12;
    format = 'pm';
  }
  if (hours < 10) {
    hours = `0${hours}`;
  }
  if (mnts < 10) {
    mnts = `0${mnts}`;
  }
  return `${hours}:${mnts} ${format}`;
}

/**
 * To Compare 2 arrays
 */
function isArrayEqual(array1, array2) {
  return _(array1)
    .differenceWith(array2, _.isEqual)
    .isEmpty();
}

/**
 * @param {string} bytes
 * @returns {string} Date
 */
function formatBytes(bytes) {
  if (bytes < 1024) return `${bytes} Bytes`;
  if (bytes < 1048576) return `${(bytes / 1024).toFixed(3)} KB`;
  if (bytes < 1073741824) return `${(bytes / 1048576).toFixed(3)} MB`;
  return `${(bytes / 1073741824).toFixed(3)} GB`;
}

function isEquivalent(obj1, obj2) {
  // Create arrays of property names
  const obj1Props = Object.getOwnPropertyNames(obj1);
  const obj2Props = Object.getOwnPropertyNames(obj2);

  // If number of properties is different,
  // objects are not equivalent
  if (obj1.length !== obj2Props.length) {
    return false;
  }

  for (let i = 0; i < obj1Props.length; i++) {
    const propName = obj1Props[i];
    if (obj1[propName] !== obj2[propName]) {
      return false;
    }
  }

  return true;
}

const mergeSavedObj = (key, data) => {
  const d = getData(key);
  if (!_.isEmpty(d)) {
    const savedData = JSON.parse(getData(key));
    // savedData.currentLab = data.currentLab;
    saveData(key, JSON.stringify(Object.assign(savedData, data)));
  }
};

const isLoggedIn = () => {
  const user = getData('user');
  if (user) {
    const userinfo = JSON.parse(user);
    return userinfo.accessToken && userinfo.statusCode !== 401;
  }
  return false;
};

const getUserRole = () => {
  const savedData = JSON.parse(getData('user'));
  if (savedData) {
    if (!isEmptyObject(savedData.currentLab)) {
      return savedData.currentLab.groupName || savedData.currentLab.currentRole;
    }
  }
  return 'LAB MEMBER';
};

const getRoleNameById = roleId => {
  switch (roleId) {
    case '683ea010-077a-11e9-8c77-59b51957ee11':
      return 'LAB MANAGER';
    case '683ea010-077a-11e9-8c77-59b51957ee12':
      return 'LAB MEMBER';
    case '683ea010-077a-11e9-8c77-59b51957ee10':
      return 'LAB OWNER';
    default:
      return 'LAB MEMBER';
  }
};

const validatePassword = pass => {
  const password = _.trim(pass);
  let error = '';
  if (password.length === 0) {
    error = 'Password is required';
  }
  if (password.length < 6) {
    error = 'Password must have 6 characters.';
  }
  if (!password.match(/\d+/g)) {
    error =
      'Your password must be a minimum of 6 characters and contain at least one number and one letter.';
  }
  if (!password.match(/[a-zA-Z]+/g)) {
    error =
      'Your password must be a minimum of 6 characters and contain at least one number and one letter.';
  }
  return error;
};

const getCurrentLab = () => {
  const savedData = JSON.parse(getData('user'));
  if (savedData) {
    if (!isEmptyObject(savedData.currentLab)) {
      return savedData.currentLab.labId;
    }
  }
  return null;
};

const getCurrentOrg = () => {
  const savedData = JSON.parse(getData('user'));
  if (savedData) {
    if (!isEmptyObject(savedData.currentLab)) {
      return savedData.currentLab.orgId;
    }
  }
  return null;
};

const getTimeZones = () => timezones;

const getCountries = () => countries;

const isValidEmail = email => {
  if (isEmail(_.trim(email))) return '';
  return 'Valid email address is required';
};

const isValidUserId = uname => {
  const userName = _.trim(uname);
  if (!userName) return 'Username can not be empty.';

  if (userName.length < 5) {
    return 'Username must be of 5 characters.';
  }
  return '';
};

const validateUserName = (name, type = '') => {
  if (!name) return `${type} name can not be empty.`;

  if (name.match(/[A-Za-z0-9]+/)) {
    return null;
  }

  return `${type} name is not valid.`;
};

const validateLabName = lname => {
  const labName = _.trim(lname);
  if (_.isEmpty(labName)) {
    return 'Lab Name is required';
  }

  if (labName.match(/[A-Za-z0-9]+/)) {
    return null;
  }
  return 'Lab Name is not valid.';
};

const validateNumbers = val => {
  if (_.isEmpty(val)) {
    return 'Value is required';
  }
  return '';
};

const getCurrentStatus = () => {
  const savedData = JSON.parse(getData('user'));

  if (!_.isEmpty(savedData)) {
    return savedData.statusCode;
  }
  return null;
};

const getModifiedMessage = alert => {
  return alert;
};

const getCurrentLabName = () => {
  const savedData = JSON.parse(getData('user'));
  if (savedData) {
    if (!isEmptyObject(savedData.currentLab)) {
      return savedData.currentLab.labName;
    }
  }
  return '';
};

const getUserId = () => {
  const savedData = JSON.parse(getData('user'));
  if (savedData) {
    return savedData.userId;
  }
  return '';
};

const getUserName = () => {
  const savedData = JSON.parse(getData('user'));
  if (savedData) {
    return savedData.userName;
  }
  return '';
};

const getCurrentLabObject = () => {
  const savedData = JSON.parse(getData('user'));
  if (savedData) {
    if (!isEmptyObject(savedData.currentLab)) {
      return savedData.currentLab;
    }
  }
  return '';
};

// eslint-disable-next-line no-underscore-dangle
export const _global = {
  isEmpty,
  isEmptyObject,
  removeData,
  setSession,
  getSession,
  clearSession,
  saveData,
  getData,
  getDate,
  getTime,
  isEquivalent,
  mergeSavedObj,
  isLoggedIn,
  getUserRole,
  getRoleNameById,
  validatePassword,
  formatBytes,
  getCurrentLab,
  getCurrentOrg,
  isArrayEqual,
  getTimeZones,
  getCountries,
  isValidEmail,
  isValidUserId,
  validateUserName,
  validateLabName,
  validateNumbers,
  getCurrentStatus,
  getModifiedMessage,
  getCurrentLabName,
  getUserId,
  compareObject,
  getUserName,
  getCurrentLabObject,
};
