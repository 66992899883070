/* eslint-disable no-unused-vars */
import React, { Component, useState, useEffect, useRef } from 'react';
import {
  Row,
  Col,
  Form,
  FormGroup,
  FormControl,
  Nav,
  Button,
  Popover,
  OverlayTrigger,
} from 'react-bootstrap';
import { PulseLoader } from 'react-spinners';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { Alert } from '../../packages/Components/Notification';
import { _global } from '../../_utils';
import { alertConstants } from '../../_constants';

const INITIAL_STATE = {
  showContact: false,
  submitted: false,
  validated: false,
  showTerms: false,
  TermsAgreed: false,
  errorShow: false,
};
const RegisterForm = ({ onSubmit, isLoading, error }) => {
  const TermsRef = useRef(null);
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [validate, setValidate] = useState(false);
  const [errorShow, setErrorShow] = useState(false);

  const [alert, setAlert] = useState(error);

  useEffect(() => {
    setAlert(error);
    return () => null;
  }, [error]);

  const [errors, setErrors] = useState({
    email: '',
    userName: '',
    password: '',
    termsCondition: '',
    country: '',
    confirmPassword: '',
  });
  const [user, setUser] = useState({
    email: '',
    userName: '',
    password: '',
    confirmPassword: '',
    country: 'US',
  });

  const validateForm = () => {
    const { email, password, confirmPassword, country, userName } = user;
    errors.email = _global.isValidEmail(email);
    errors.userName = _global.isValidUserId(userName);

    if (password.localeCompare(confirmPassword) === 0) {
      errors.password = _global.validatePassword(password);
      errors.confirmPassword = _global.validatePassword(confirmPassword);
    } else {
      errors.password = 'Passwords should match';
      errors.confirmPassword = 'Passwords should match';
    }
    if (!country) errors.country = 'Select country.';
    if (!termsAgreed) {
      errors.termsCondition = 'Please accept Terms & Consitions';
    }
    setErrors(errors);
    setErrorShow(true);
    // eslint-disable-next-line no-console
    console.log(errors);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    clearErrors();
    validateForm();
    setSubmitted(true);
    if (
      _.isEmpty(errors.password) &&
      _.isEmpty(errors.confirmPassword) &&
      _.isEmpty(errors.email) &&
      _.isEmpty(errors.userName) &&
      termsAgreed
    ) {
      setValidate(false);
      const payload = {
        username: user.userName.trim(),
        email: user.email.trim(),
        password: user.password,
        country: user.country,
      };

      onSubmit(payload);
    }
  };

  const clearErrors = () => {
    setErrors({
      email: '',
      userName: '',
      password: '',
      confirmPassword: '',
      termsCondition: '',
    });
    setErrorShow(false);
  };

  const clearForm = () => {
    setUser({
      email: '',
      userName: '',
      password: '',
      confirmPassword: '',
      country: '',
    });
  };

  const formValidationState = field => {
    let status = null;
    if (!_.isEmpty(errors[field])) {
      status = 'error';
    }
    return status;
  };

  const handleChange = event => {
    if (errorShow) {
      clearErrors();
    }
    const { name, value } = event.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  const onCountrySelect = e => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const onAgreeTerms = () => {
    const TermsAgreed = TermsRef.current.checked;
    if (TermsAgreed) {
      errors.termsCondition = '';
    } else {
      errors.termsCondition = 'Please accept Terms & Condition';
    }
    setTermsAgreed(TermsAgreed);
    setErrors(errors);
  };

  const popoverUsername = (
    <Popover>
      <strong>
        Minimum 5 characters required. Special characters not allowed
      </strong>
    </Popover>
  );

  const popoverPassword = (
    <Popover>
      <strong>
        Minimum of 6 characters with atleast one digit and one alphabet is
        required
      </strong>
    </Popover>
  );

  return (
    <React.Fragment>
      <Row>
        <Col xl={6}>
          <h2 className="mt-2 mb-4">Register</h2>
        </Col>
      </Row>
      <Form name="form" noValidate validated={validate}>
        {alert && (
          <Alert variant={'danger'} onClose={() => setAlert(null)}>
            {alert}
          </Alert>
        )}
        {/* Email Address */}
        <FormGroup
          // className="form-group mt-2"
          controlId="email-address"
          validationstate={formValidationState('email')}
        >
          <Form.Label>
            Email
            <span className="text-danger">*</span>
          </Form.Label>
          <FormControl
            type="email"
            maxLength={60}
            className="form-control input-control"
            placeholder="Enter your email address"
            aria-label="email"
            name="email"
            onChange={handleChange}
            value={user.email}
            required
            pattern="[A-Za-z0-9]+@[a-z0-9.-]+\.[a-zA-Z]{3}"
          />

          {submitted && errors.email ? (
            <span className="error-msg">{errors.email}</span>
          ) : (
            ''
          )}
        </FormGroup>
        {/* Username */}
        <FormGroup
          // className="form-group mt-2"
          controlId="user-name"
          validationstate={formValidationState('userName')}
        >
          <Form.Label>
            Username <span className="text-danger">* </span>
            <OverlayTrigger
              trigger="hover"
              placement="right"
              overlay={popoverUsername}
            >
              <Nav.Link className="p-0 d-inline">
                <i className="fal fa-info-circle text-muted" />
              </Nav.Link>
            </OverlayTrigger>
          </Form.Label>
          <FormControl
            type="text"
            minLength={6}
            className="form-control input-control"
            placeholder="Enter your username"
            aria-label="user-name"
            name="userName"
            onChange={handleChange}
            value={user.userName}
            required
          />

          {submitted && errors.userName ? (
            <span className="error-msg">{errors.userName}</span>
          ) : (
            ''
          )}
        </FormGroup>
        {/* Password */}
        <FormGroup
          className="form-group mt-2"
          validationstate={formValidationState('password')}
        >
          <Form.Label>
            {' '}
            Password <span className="text-danger">* </span>
            <OverlayTrigger
              trigger="hover"
              placement="right"
              overlay={popoverPassword}
            >
              <Nav.Link className="p-0 d-inline">
                <i className="fal fa-info-circle text-muted" />
              </Nav.Link>
            </OverlayTrigger>
          </Form.Label>
          <FormControl
            type="password"
            maxLength={30}
            className="form-control input-control"
            placeholder="Enter a password"
            aria-label="Password"
            name="password"
            onChange={handleChange}
            value={user.password}
            required
          />
          {submitted && errors.password ? (
            <span className="error-msg">{errors.password}</span>
          ) : (
            ''
          )}
        </FormGroup>
        {/* Confirm Password */}
        <FormGroup
          className="form-group mt-2"
          validationstate={formValidationState('confirmPassword')}
        >
          <Form.Label>
            Confirm Password
            <span className="text-danger">*</span>
          </Form.Label>
          <FormControl
            type="password"
            maxLength={30}
            className="form-control input-control"
            placeholder="Enter confirm password"
            aria-label="Password"
            name="confirmPassword"
            onChange={handleChange}
            value={user.confirmPassword}
            required
          />

          {submitted && errors.confirmPassword ? (
            <span className="error-msg">{errors.confirmPassword}</span>
          ) : (
            ''
          )}
        </FormGroup>
        {/* Country */}
        <Form.Group className="form-group mt-2">
          <Form.Label>
            Country
            <span className="text-danger">*</span>
          </Form.Label>
          <Form.Control
            as="select"
            name="country"
            value={user.country}
            onChange={onCountrySelect}
            className="form-control input-control"
          >
            {_global.getCountries().map((cntry, index) => (
              <option key={index.toString()} value={cntry.code}>
                {cntry.name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        <Row>
          <Col xl={12} className="p-0">
            <Col className="mt-2 custom-check">
              <label className="pl-4">
                <input
                  type="checkbox"
                  className="mr-1"
                  ref={TermsRef}
                  onClick={onAgreeTerms}
                />
                <span className="checkmark" />
                <p
                  className="text-justify"
                  style={{
                    marginTop: '-20px',
                    marginBottom: '0px',
                  }}
                >
                  I agree{' '}
                </p>
              </label>
              {submitted && errors.termsCondition ? (
                <span className="error-msg pl-4">{errors.termsCondition}</span>
              ) : (
                ''
              )}
            </Col>
          </Col>
        </Row>

        <Col className="text-right">
          <Form.Group>
            <Link
              to="/login"
              style={isLoading ? { pointerEvents: 'none' } : null}
            >
              Account Login?
            </Link>
          </Form.Group>
        </Col>
        <Row className="mt-1 pt-3">
          <Col>
            <Button
              id="btn-login"
              onClick={handleSubmit}
              disabled={isLoading}
              className="btn btn-auth-primary btn-block font-weight-light"
            >
              {isLoading ? (
                <PulseLoader color="#fff" isLoading={isLoading} />
              ) : (
                'Register'
              )}
            </Button>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
};

RegisterForm.defaultProps = {
  isLoading: false,
  userRegistration: () => null,
  clearAlert: () => null,
  clearAlertByTimeout: () => null,
  alert: {
    type: '',
    message: '',
    show: false,
  },
};

export default RegisterForm;
