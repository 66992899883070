/* eslint-disable no-console */
import React from 'react';
import HomeHeader from '../common/Header';
import CookieBanner from './CookieBanner';
export const AltLayout = props => {
  return (
    <React.Fragment>
      <HomeHeader {...props} />
      <div className="body-container">{props.children}</div>
      <CookieBanner />
    </React.Fragment>
  );
};
