import React, { useState } from 'react';
import { PulseLoader } from 'react-spinners';
import _ from 'lodash';

import { Row, Col, Form, FormControl, Button } from 'react-bootstrap';
import { _global } from '../../_utils';

import { keyConstants } from '../../_constants';
import { Alert } from '../../packages/Components/Notification';

const ForgotPassword = ({ onSubmit, error, isLoading, message }) => {
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({
    email: '',
  });

  const handleChange = e => {
    e.preventDefault();
    setEmail(e.target.value);
  };
  const handleSubmit = e => {
    e.preventDefault();
    const errors = validate();
    if (_.isEmpty(errors)) {
      onSubmit(email && email.trim());
      setErrors({ email: '' });
    }
    setErrors(errors);
  };

  const handleEnterKeyPress = e => {
    if (e.charCode === keyConstants.ENTER) {
      handleSubmit(e);
    }
  };

  const validate = () => {
    let validation;
    let errors = {};
    validation = _global.isValidEmail(email);
    if (!_.isEmpty(validation)) {
      errors.email = validation;
    }
    return errors;
  };
  return (
    <React.Fragment>
      <Row>
        <Col>
          <h2 className="mt-4 mb-4">Forgot Password</h2>
        </Col>
      </Row>
      <form name="form" onSubmit={handleSubmit}>
        {error && (
          <Alert variant={'danger'} onClose={() => null}>
            {error}
          </Alert>
        )}
        {message && (
          <Alert variant={'success'} onClose={() => null}>
            {message}
          </Alert>
        )}
        <Form.Group>
          <Form.Label>
            Email
            <span className="text-danger">*</span>
          </Form.Label>
          <FormControl
            maxLength={60}
            type="text"
            className="form-control input-control"
            placeholder="Enter your email"
            id="email"
            aria-label="email"
            name="email"
            aria-describedby="basic-addon1"
            onChange={handleChange}
            onKeyPress={handleEnterKeyPress}
            value={email || ''}
          />
          {errors.email && (
            <div id="alert-username" className="text-danger">
              {errors.email}
            </div>
          )}
        </Form.Group>
        <Row className="mt-5 pt-3">
          <Col>
            <Button
              type="submit"
              id="btn-login"
              name="login"
              disabled={isLoading}
              className="btn btn-auth-primary btn-block"
            >
              {isLoading ? (
                <PulseLoader color="#fff" isLoading={isLoading} />
              ) : (
                'Submit'
              )}
            </Button>
          </Col>
        </Row>
      </form>
    </React.Fragment>
  );
};

export default ForgotPassword;
