/**
 * Summary: keyConstants
 * Description: define the keyConstants
 * @author Blab Team
 * @date  08.11.18
 */

export const keyConstants = {
  ENTER: 13,
};
