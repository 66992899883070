import React from 'react';
import ReactDOM from 'react-dom';
import Routing from './routes';
import './assets/scss/index.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

import ConfigCtxProvider from './HOCs/configCtx';
import Apollo from './HOCs/apollo';
import { BrowserRouter as Router } from 'react-router-dom';
function Root() {
  return (
    <ConfigCtxProvider>
      <Apollo>
        <Router>
          <Routing />
        </Router>
      </Apollo>
    </ConfigCtxProvider>
  );
}

ReactDOM.render(<Root />, document.getElementById('app'));
