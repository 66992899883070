import { AuthLayout } from '../containers/layouts/AuthLayout';
import { AltLayout } from '../containers/layouts/AltLayout';
import LoginPage from './login';
import Homepage from './home';

import ForgotPasswordPage from './forgot-password';
//import ValidateAuthToken from '../ValidateAuthToken';
import RegisterPage from './register';
import StartLearning from '../components/Learn';
//import StartLearning from '../Learn';
//import DashboardPage from '../Dashboard';

/**
 * The routes array returned will be fed into a "Switch" component from react-router.
 * Order does matter since the "Switch" will take the first matching route
 */
const routes = [
  {
    id: 1,
    path: '/',
    exact: true,
    routeLayout: AltLayout,
    routeComponent: Homepage,
    privateRoute: false,
  },
  {
    id: 2,
    path: '/login',
    exact: true,
    routeLayout: AuthLayout,
    routeComponent: LoginPage,
    privateRoute: false,
  },
  {
    id: 3,
    path: '/register',
    exact: true,
    routeLayout: AuthLayout,
    routeComponent: RegisterPage,
    privateRoute: false,
  },
  {
    id: 3,
    path: '/learn',
    exact: true,
    routeLayout: AltLayout,
    routeComponent: StartLearning,
    privateRoute: false,
  } /*
  {
    id: 3,
    path: '/dashboard',
    exact: true,
    routeLayout: AltLayout,
    routeComponent: DashboardPage,
    privateRoute: true,
  },
  {
    id: 70,
    path: '/validate-auth-token',
    routeLayout: AuthLayout,
    routeComponent: ValidateAuthToken,
    privateRoute: false,
  },*/,
  {
    id: 3,
    path: '/forgot',
    exact: true,
    routeLayout: AuthLayout,
    routeComponent: ForgotPasswordPage,
    privateRoute: false,
  },
];

export default routes;
