import React, { useState } from 'react';
import MasterControl from './controls/master';
import Data from './controls/controlsData';
import { PageLoader } from '../../packages/Components/Loader';
import './catalog.scss';
const careControls = () => {
  const [loading, setLoading] = useState(true);
  const [isMaster, setIsMaster] = useState(true);
  const [child, setChild] = useState(Data.children || []);

  setTimeout(() => {
    setLoading(false);
  }, 1000);
  const handleClick = index => {
    setIsMaster(false);
    if (index >= 0) {
      setChild(childs => {
        return childs[index]['children'];
      });
    } else {
      setChild(childs => childs['children']);
    }
  };

  const startOver = () => {
    setIsMaster(true);
    setChild(Data.children);
  };
  return (
    <div className="hero-selector">
      {loading ? (
        <PageLoader />
      ) : (
        <React.Fragment>
          <MasterControl
            startOver={startOver}
            childrenData={child}
            isMaster={isMaster}
            handleClick={handleClick}
          />
        </React.Fragment>
      )}
    </div>
  );
};

export default careControls;
