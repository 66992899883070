/* eslint-disable no-unused-vars */
import React, { Fragment, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import SearchIcon from '../../../../assets/img/search.svg';
import BookmarkIcon from '../../../../assets/img/bookmark.svg';
import ProfileIcon from '../../../../assets/img/profile.svg';
import NotifyIcon from '../../../../assets/img/notification.svg';

import './rightMenu.scss';
const RightMenu = ({ history }) => {
  const [showSearch, setShowSearch] = useState(false);
  const [searchText, setSearchText] = useState('');
  const menuClick = type => {
    if (type === 'bookmakrs') {
      history.push('/bookmarks');
    }
  };

  const resetMenu = () => {};

  const onSearch = event => {
    if (event.charCode === 13) {
      const { target } = event;
      onSearchSubmit(target.value);
    }
  };

  const onSearchChange = event => {
    const val = event.target.value.trim();
    setSearchText(val);
  };

  function onSearchSubmit(value) {
    const val = value.trim();
    if (val && val.length > 0) {
      history.push(`/search/${val}`);
    }
  }
  return (
    <Fragment>
      {showSearch && (
        <div className="rightMenu-search">
          <input
            className="rightMenu-search-box"
            placeholder="Search"
            type="text"
            value={searchText}
            title="Enter the terms you wish to search for."
            onKeyPress={onSearch}
            onChange={onSearchChange}
          />
        </div>
      )}
      <ul className="rightMenu_list">
        <li className="rightMenu_item">
          <span className="rightMenu_profile">
            <img
              className="rightMenu_img"
              src={SearchIcon}
              alt="profile-icon"
              onClick={() => setShowSearch(i => !i)}
            />
          </span>
        </li>
        <li className="rightMenu_item">
          <span className="rightMenu_profile">
            <img
              className="rightMenu_img"
              src={BookmarkIcon}
              alt="profile-icon"
              onClick={() => setShowSearch(i => !i)}
            />
          </span>
        </li>
        <li className="rightMenu_item">
          <span className="rightMenu_profile">
            <img
              className="rightMenu_img"
              src={NotifyIcon}
              alt="profile-icon"
              onClick={() => setShowSearch(i => !i)}
            />
          </span>
        </li>
        <li className="rightMenu_item">
          <Link to="/bookmarks" className="rightMenu_bookmark">
            <img
              className="rightMenu_img"
              src={ProfileIcon}
              onClick={() => menuClick('bookmark')}
              alt="bookmark-icon"
            />
          </Link>
        </li>
      </ul>
    </Fragment>
  );
};
export default RightMenu;
