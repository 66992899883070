/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { ApolloContext } from 'react-apollo';
import LoginComponent from '../components/login';
import loginQuery from '../graphql/query/login';
import UserInfoHook from '../hooks/userinfo';

export default function Login() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [, setUserInfo] = UserInfoHook();
  const { client } = useContext(ApolloContext);
  const [isLoginCompleted, setIsLoginCompleted] = useState(false);

  function onLoginResponse({ loginData }) {
    const { token } = loginData;
    if (!token) {
      setError('Something went wrong!');
      setIsLoading(false);
      return;
    }
    setLocalStorage(loginData);
  }

  function onLoginError(
    { graphQLErrors, networkError } = { networkError: 'Something went wrong!' },
  ) {
    if (networkError) {
      setError('Something went wrong!');
      setIsLoading(false);
      return;
    }
    const [firstError] = graphQLErrors;
    setError(firstError.message);
    setIsLoading(false);
  }
  function setLocalStorage({ token, profile }, isFirstTime = false) {
    localStorage.setItem('auth-token', token);
    setUserInfo({ ...profile, isFirstTime });
    setIsLoginCompleted(true);
  }

  const tryLogin = ({ email, password }) => {
    setError('');
    setIsLoading(true);
    client
      .query({
        fetchPolicy: 'no-cache',
        query: loginQuery,
        variables: {
          email,
          password,
        },
      })
      .then(({ data }) => onLoginResponse(data))
      .catch(onLoginError);
  };

  if (isLoginCompleted) {
    return <Redirect to="/" />;
  }

  return (
    <div className="page__container">
      <LoginComponent onSubmit={tryLogin} isLoading={isLoading} error={error} />
    </div>
  );
}
