import React from 'react';
import './homebanner.scss';

// import Bg from '../../assets/img/bg1.png';
const Banner = () => {
  return (
    <div className="home-wrapper">
      <div className="container">
        <div className="header__background"></div>
        <div className="header__content text-md-center">
          <div className="home--hero-header">
            <div className="col-xs-12 text-center hero-header-container">
              <div className="hero-header-title">
                Find Best only Trainer Available worldwide
              </div>
              <div className="hero-header-subtitle">
                Level up your programming skills today with condensed video
                lessons on industry leading web frameworks and tools!
              </div>
              <div className="formContainer clearfix">
                <>
                  <input
                    id="keyword"
                    type="text"
                    className="categoryEventService formSearchInput topicAutoSuggest categoryLoader ui-autocomplete-input"
                    name="category"
                    placeholder="Enter categories e.g. Maths, Java, French"
                    autoComplete="off"
                  ></input>
                  <input
                    type="button"
                    value="Search"
                    className="formInputBtn homeSrchBtn"
                  ></input>
                </>
              </div>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
