import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { _global } from '../_utils';
/**
 * This is a private/protected route.
 * If token exists, navigates to requested route.
 * Otherwise naviagtes to login page
 */

const PrivateRoute = ({ render: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      _global.isLoggedIn() ? <Component {...props} /> : <Redirect to="/login" />
    }
  />
);

export default PrivateRoute;
