import React from 'react';
import Child from './child';

const Master = ({ isMaster, handleClick, childrenData, startOver }) => {
  return isMaster ? (
    <React.Fragment>
      <h2 className="hero-selector__title">Lets get started</h2>
      <div className="hero-selector__actions">
        {childrenData.map((data, index) => {
          return (
            <div className="hero-selector__actions--grid" key={index}>
              <button
                onClick={() => handleClick(index)}
                type="button"
                className="nav-link btn-primary-control"
                id="find-care"
              >
                <span>{data.btn}</span>
              </button>
            </div>
          );
        })}
      </div>
    </React.Fragment>
  ) : (
    <Child
      startOver={startOver}
      handleClick={handleClick}
      childrenData={childrenData}
    />
  );
};
export default Master;
