import React, { Component } from 'react';
import './home.scss';
import Banner from './homeBanner';
import Bg1 from '../../assets/img/bg1.png';

import { Row, Col, Container } from 'react-bootstrap';
class HomePage extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="page-wrapper">
          <Banner />
        </div>
        <div className="body-container page-body">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="collageContainer">
                  <div className="titleContainer">
                    <h1>
                      Join the Millions <br /> Learning to Code &<br /> Test
                      knowledge <br /> with Quiz
                    </h1>
                  </div>
                  <img
                    src={Bg1}
                    alt="a happy Code academy learner"
                    className="learner"
                  />
                  <img
                    src="https://www.codecademy.com/webpack/renderer/f059ec67021b3dfeea1b6cb2eb373683.svg"
                    alt=""
                    className="bars"
                  />
                  <img
                    src="https://www.codecademy.com/webpack/renderer/daecb491e565bc6b7390756d03fc1ee1.svg"
                    alt=""
                    className=""
                  />
                  <img
                    src="https://www.codecademy.com/webpack/renderer/5c537833d79f7065497b565eaa7bca64.svg"
                    alt=""
                    className="rectangle"
                  />
                </div>
              </Col>
              <Col xs={12} md={6} className="featuresGrid">
                <div className="feature">
                  <img
                    alt=""
                    className="feature__icon"
                    src="https://hackr.io/assets/images/nav-icons/programming.svg"
                  />
                  <div className="feature__content">
                    <div className="feature__content__heading">
                      Real-World Projects
                    </div>
                    <p className="feature__content__description">
                      Learn more with exclusive courses, quizzes, and extra
                      practice content.
                    </p>
                  </div>
                </div>
                <div className="feature">
                  <img
                    alt=""
                    className="feature__icon"
                    src="https://hackr.io/assets/images/nav-icons/programming.svg"
                  />
                  <div className="feature__content">
                    <div className="feature__content__heading">
                      A Step-by-Step Roadmap
                    </div>
                    <p className="feature__content__description">
                      Paths show you where to start and what to learn next to
                      reach your content.
                    </p>
                  </div>
                </div>
                <div className="feature">
                  <img
                    alt=""
                    className="feature__icon"
                    src="https://hackr.io/assets/images/nav-icons/programming.svg"
                  />
                  <div className="feature__content">
                    <div className="feature__content__heading">
                      Real-World Projects
                    </div>
                    <p className="feature__content__description">
                      Learn more with exclusive courses, quizzes, and extra
                      practice content.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default HomePage;
