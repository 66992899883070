import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const Child = ({ handleClick, childrenData, startOver }) => {
  return (
    <React.Fragment>
      <button
        type="button"
        onClick={startOver}
        className="btn back-button btn-link-secondary"
      >
        <span className="icon icon-back-chevron-lg">
          <FontAwesomeIcon icon={faArrowLeft} />
        </span>

        <span>Start over</span>
      </button>
      <h2 className="hero-selector__title">Test Your Tech Knowledge</h2>
      <div className="grid-row">
        <div className="grid-col text-center">
          {childrenData &&
            childrenData.map((data, index) => {
              return (
                <button
                  key={index}
                  onClick={() => handleClick(index)}
                  type="button"
                  className="btn btn-next-step btn-choice"
                >
                  {data.children && data.children.length > 0 ? (
                    <img
                      className="nav-icon"
                      src={data.img}
                      alt="Programming"
                    />
                  ) : (
                    <img
                      className="nav-icon-original"
                      src={data.img}
                      alt="Programming"
                    />
                  )}
                  <span className="h3">{data.name}</span>
                </button>
              );
            })}
        </div>
      </div>
    </React.Fragment>
  );
};
export default Child;
