import React from 'react';
import PropTypes from 'prop-types';
import { Alert as AlertBS } from 'react-bootstrap';

import './assets/css/style.css';
import { _global } from '../../../_utils';

const propTypes = {
  /**
   * Sets the class for Alert component.
   */
  className: PropTypes.string,

  /**
   * The Alert visual variant
   *
   * @type {'danger' | 'warning' | 'info' }
   */
  variant: PropTypes.string,

  /**
   * To display close button on right
   */
  dismissible: PropTypes.bool,
};

const defaultProps = {
  variant: 'default',
  dismissible: false,
};

export class Alert extends React.Component {
  shouldComponentUpdate() {
    return true;
  }

  render() {
    const bsPrefix = 'alert';

    const { children, className, variant, ...props } = this.props;

    const classes = `${className} ${variant && `${bsPrefix}-${variant}`}`;

    return (
      <AlertBS ref={this.ref} dismissible className={classes} {...props}>
        {_global.getModifiedMessage(children)}
      </AlertBS>
    );
  }
}

Alert.propTypes = propTypes;
Alert.defaultProps = defaultProps;
