import React, { Component } from 'react';
// import { css } from '@emotion/core';
import { FadeLoader } from 'react-spinners';
import './style.css';

export class Loader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  render() {
    const { loading } = this.state;
    return (
      <div className="loader-position">
        <FadeLoader
          sizeUnit="px"
          size={150}
          color="#559ad0"
          loading={loading}
        />
      </div>
    );
  }
}
