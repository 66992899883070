/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
// import config from 'config';
import { _global } from '../../_utils';
import Google from '../../assets/img/google.svg';
import Facebook from '../../assets/img/fb.svg';
import Github from '../../assets/img/github.svg';
import { Alert } from '../../packages/Components/Notification';

import {
  Row,
  Col,
  Form,
  Button,
  FormGroup,
  FormControl,
  InputGroup,
} from 'react-bootstrap';
import { keyConstants } from '../../_constants';
/**
 * Description: local initial state of the route
 */
const INITIAL_STATE = {
  email: '',
  password: '',
  submitted: false,
  user: {},
  navigating: false,
  showContact: false,
  errors: {
    uemail: '',
    password: '',
  },
};

const LoginForm = ({ onSubmit, error, isLoading }) => {
  const [errors, setErrors] = useState({
    uemail: '',
    password: '',
  });
  const [submitted, setSubmitted] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [alert, setAlert] = useState(error);

  useEffect(() => {
    setAlert(error);
    return () => null;
  }, [error]);
  /**
   * Description: handle the change event
   * @param {object}
   * @return {null}
   */
  const handleChange = e => {
    const { name, value } = e.target;
    if (name === 'email') {
      setEmail(value);
    } else {
      setPassword(value);
    }
    if (submitted) {
      const errors = validateInputs();
      setErrors({ errors });
    }
  };

  const validateInputs = () => {
    let uError = '';
    let passError = '';
    uError = _global.isValidEmail(email);

    passError = _global.validatePassword(password);
    return {
      email: uError,
      password: passError,
    };
  };

  /**
   * Description: handle the enter key press event
   * @param {object}   e
   * @return {null}
   */
  const handleEnterKeyPress = e => {
    if (e.charCode === keyConstants.ENTER) {
      handleSubmit(e);
    }
  };

  /**
   * Description: handle the submit button
   * @param {object}
   * @return {null}
   */
  const handleSubmit = e => {
    e.preventDefault();
    setSubmitted(true);
    const errors = validateInputs();
    if (errors.email === '' && errors.password === '') {
      onSubmit({ email: email.trim(), password });
    } else {
      setErrors(errors);
    }
  };

  return (
    <React.Fragment>
      <Row>
        <Col>
          <h2 className="mt-2 mb-4">Get Started</h2>
        </Col>
      </Row>
      {alert && (
        <Alert variant={'danger'} onClose={() => setAlert(null)}>
          {error}
        </Alert>
      )}
      <form name="form" onSubmit={handleSubmit}>
        <FormGroup>
          <Form.Label>Email</Form.Label>
          <InputGroup>
            <FormControl
              type="text"
              className="form-control input-control"
              placeholder="name@mail.com"
              id="email"
              onChange={handleChange}
              aria-label="Email"
              name="email"
              aria-describedby="basic-addon1"
            />
          </InputGroup>
          {submitted && (
            <div id="alert-email" className="text-danger">
              {errors.email}
            </div>
          )}
        </FormGroup>
        <FormGroup>
          <Form.Label>Password</Form.Label>
          <InputGroup>
            <FormControl
              type="password"
              onChange={handleChange}
              maxLength={20}
              className="form-control input-control"
              placeholder="Password"
              id="password"
              aria-label="Password"
              name="password"
              aria-describedby="basic-addon1"
            />
          </InputGroup>
          {submitted && (
            <div id="alert-password" className="text-danger">
              {errors.password}
            </div>
          )}
        </FormGroup>
        <Row>
          <Col className="text-right">
            <Form.Group>
              <Link
                to="/forgot"
                style={isLoading ? { pointerEvents: 'none' } : null}
              >
                Forgot password? <br />
              </Link>
              <Link
                to="/register"
                style={isLoading ? { pointerEvents: 'none' } : null}
              >
                Create New Account?
              </Link>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-1 pt-3">
          <Col>
            <Button
              onClick={handleSubmit}
              type="button"
              id="btn-login"
              name="login"
              disabled={isLoading}
              className="btn btn-auth-primary btn-block"
            >
              {isLoading ? (
                <PulseLoader color="#fff" isLoading={isLoading} />
              ) : (
                'Start Coding Now'
              )}
            </Button>
          </Col>
        </Row>
        <span className="social-option">Or, use another account: </span>
        <Row className="mt-1 pt-3">
          <Col className="social-btn-group">
            <a
              href={'/'}
              id="btn-login"
              name="login"
              className="btn social-btn"
            >
              <img src={Google} />
            </a>
            <a
              href={'/'}
              id="btn-login"
              name="login"
              className="btn social-btn"
            >
              <img src={Facebook} />
            </a>
            <a
              href={'/'}
              id="btn-login"
              name="login"
              className="btn social-btn"
            >
              <img src={Github} />
            </a>
          </Col>
        </Row>
      </form>
    </React.Fragment>
  );
};

export default LoginForm;
