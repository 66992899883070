import React, { Component } from 'react';
import CookieConsent from 'react-cookie-consent';

class CookieBanner extends Component {
  render() {
    return (
      <CookieConsent
        location="bottom"
        buttonText="I Accept"
        cookieName="myAwesomeCookieName2"
        expires={150}
      >
        <span>
          We use cookies on this website to enhance the user experience and
          provide the best possible service. By continuing to browse the
          website, you consent to our use of cookies. Learn more on our{' '}
          <a
            className="text-primary"
            href="https://www.sigmaaldrich.com/help-welcome/registration/privacy-policy.html"
            target=""
          >
            Data Privacy Policy
          </a>
        </span>
        .
      </CookieConsent>
    );
  }
}

export default CookieBanner;
