import React from 'react';
import { Switch, Route } from 'react-router-dom';
import routes from './app-routes';
import PrivateRoute from './private-route';

/**
 * Routes are iterated to build the different routes needed
 */
const SwitchRoutes = () => (
  <div className="page">
    <Switch>
      {routes.map(route =>
        route.privateRoute ? (
          <PrivateRoute
            key={route.id}
            exact={route.exact}
            path={route.path}
            render={props => (
              <route.routeLayout>
                <route.routeComponent {...props} />
              </route.routeLayout>
            )}
          />
        ) : (
          <Route
            key={route.id}
            exact={route.exact}
            path={route.path}
            render={props => (
              <route.routeLayout>
                <route.routeComponent {...props} />
              </route.routeLayout>
            )}
          />
        ),
      )}
    </Switch>
  </div>
);

export default SwitchRoutes;
