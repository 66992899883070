import React, { Fragment, useContext } from 'react';
import Mlogo from '../../../../assets/img/log.png';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ApolloContext } from 'react-apollo';
import './header.scss';
import UserInfoHook from '../../../../hooks/userinfo';
import RightMenu from './rightMenu';
import { withRouter } from 'react-router-dom';

const HomeHeader = ({ history }) => {
  const [user] = UserInfoHook();
  const { client } = useContext(ApolloContext);

  // eslint-disable-next-line no-undef
  const logout = () => {
    client.cache.reset();
    localStorage.clear();
    history.push('/login');
  };

  return (
    <Fragment>
      <header className="header">
        <div className="full-width">
          <Navbar>
            <Navbar.Brand>
              <Link to="/">
                <img
                  src={Mlogo}
                  style={{ width: '100px', height: '35px' }}
                  alt="Code School"
                />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse
              id="basic-navbar-nav"
              className="justify-content-start"
            >
              <Nav.Item>
                <Link to="/learn" className="nav-link">
                  Catalog
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link to="/learn" className="nav-link">
                  Training
                </Link>
              </Nav.Item>
            </Navbar.Collapse>
            {user.email ? (
              <Navbar.Collapse
                id="basic-navbar-nav"
                className="justify-content-end"
              >
                <Nav.Item>
                  <Link
                    to="/learn"
                    className="btn btn-primary-border nav-link  br-0"
                  >
                    Start Learning
                  </Link>
                </Nav.Item>
                <Nav.Item>
                  <a
                    onClick={logout}
                    className="btn btn-primary-border br-0 nav-link link-item-sm"
                  >
                    Logout
                  </a>
                </Nav.Item>
              </Navbar.Collapse>
            ) : (
              <Navbar.Collapse
                id="basic-navbar-nav"
                className="justify-content-end"
              >
                <Nav.Item>
                  <Link
                    to="/learn"
                    className="btn btn-primary-border nav-link  br-0"
                  >
                    Start Learning
                  </Link>
                </Nav.Item>
                <Nav.Item className="link-item-sm">
                  <Link to="/login" className="nav-link link-item-sm">
                    Login
                  </Link>
                </Nav.Item>
                <Nav.Item className="link-item-sm">
                  <Link
                    to="/register"
                    className="nav-link btn-primary-main link-item-sm"
                  >
                    Sign Up
                  </Link>
                </Nav.Item>
              </Navbar.Collapse>
            )}
          </Navbar>
        </div>
      </header>
      <header className="header-mobile">
        <div className="full-width">
          <Navbar>
            <button type="button" className="hamburger" />
            <Navbar.Brand>
              <Link to="/">
                <img
                  src={Mlogo}
                  style={{ width: '100px', height: '35px' }}
                  alt="Code School"
                />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse
              id="basic-navbar-nav"
              className="justify-content-end right-section"
            >
              <RightMenu />
            </Navbar.Collapse>
          </Navbar>
        </div>
        <div className="overlay" />
      </header>
      <div className="popup-overlay" />
    </Fragment>
  );
};

export default withRouter(HomeHeader);
